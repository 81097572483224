<style lang="less" scoped>
/deep/ .ant-table-body {
  box-sizing: border-box;
}
.ant-form-item-label {
  width: 90px !important;
  text-align: left !important;
}
/deep/ .ant-form-item-label{
  text-align: left;
}
.table-wrapper {
  margin-bottom: 50px;
}
/deep/ .ant-table-tbody tr {
  background-color: #fff;
}
  .ant-col-md-7 {
    padding-right: 0 !important;
  }

.formInquire {
  padding-top: 25px;
  background: #fff;
  padding-left: 20px;
  /deep/ .ant-form-item-label {
    width: 80px !important;
  }
}
/deep/ .ant-table-row {
  /deep/ td {
    // text-align: left !important;
    background: pink;
  }
}
.ant-table table {
  table-layout: fixed;
}
.ant-table-tbody tr td span {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dislodge-script{
 /deep/ .ant-form-item-label label::after{
    content:'' !important;
  }
}
</style>
<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" class="formInquire">
        <a-row :gutter="48" class="orderTop">
          <a-col :md="7" :sm="12">
            <a-form-item label="订单状态">
              <a-select v-model="queryParam.orderState" placeholder="全部" style="width: 80%">
                <a-select-option :key="item.enumValue" v-for="item in orderStateData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="运输状态">
              <a-select v-model="queryParam.transportState" placeholder="全部" style="width: 80%">
                <a-select-option :key="item.enumValue" v-for="item in transportStateData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="派送状态">
              <a-select v-model="queryParam.dispatchState" placeholder="全部" style="width: 80%">
                <a-select-option :key="item.enumValue" v-for="item in dispatchStateData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="取货状态">
              <a-select v-model="queryParam.pickupState" placeholder="全部" style="width: 80%">
                <a-select-option :key="item.enumValue" v-for="item in pickupStateData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderSn" placeholder="" style="width: 80%" />
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="运单号">
              <a-input v-model="queryParam.transportSn" placeholder="" style="width: 80%" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="48">
          <a-col :md="7" :sm="12">
            <a-form-item
              label="下单时间"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-date-picker
                style="width: 80%"
                v-model="queryParam.startCreateDateVal"
                show-time
              />
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="12">
            <a-form-item
              class="dislodge-script"
              label="至"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-date-picker
                style="width: 80%"
                v-model="queryParam.endCreateDateVal"
                show-time
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="12">
            <a-form-item label="企业客户">
              <a-select v-model="queryParam.paymentCustomerCode" placeholder="全部" style="width: 72%">
                <a-select-option :key="item.code" v-for="item in customerCodeList" :value="item.code">
                  {{ item.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          
        </a-row>

        <a-row :gutter="48">
            <a-col>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 10px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="orderSn"
      :columns="columns"
      :data="loadData"
      :page-size="10"
      :scroll="{ y: 420, x: 'max-content' }"
      class="tableOrder"
    >
      <span slot="luxian" slot-scope="text, record">
        <template v-if="record.shipperGeoInfo != ''">
          {{ record.shipperGeoInfo.cityName }} 至 {{ record.receiverGeoInfo.cityName }}
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)" v-action:orderDetailButton>详情</a>
           <!-- v-action:orderDetailButton -->
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getOrderManagerList } from '@/api/order'
import { enumerationData } from '@/api/common'
import { findListCustomer } from '@/api/manage'
import moment from 'moment'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
  },
  data() {
    return {
      mdl: {},
      pickupStateData: {},
      dispatchStateData: {},
      transportStateData: {},
      customerCodeList:[],
      orderStateData: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          align: 'center',
          dataIndex: 'orderSn',
          fixed: 'left',
          width: '200px',
        },
        {
          title: '运单号',
          align: 'center',
          width: '220px',
          dataIndex: 'transportSn',
        },
        {
          title: '下单时间',
          align: 'center',
          width: '200px',
          dataIndex: 'createDate',
        },
        {
          title: '路线',
          align: 'center',
          width: '200px',
          dataIndex: 'luxian',
          scopedSlots: { customRender: 'luxian' },
        },
        {
          title: '提货类型',
          width: '150px',
          align: 'center',
          dataIndex: 'pickupTypeVal',
          ellipsis: 'true',
        },
        {
          title: '派送类型',
          width: '150px',
          align: 'center',
          dataIndex: 'dispatchTypeVal',
        },
        {
          title: '订单类型',
          dataIndex: 'orderTypeVal',
          width: '150px',
          align: 'center',
        },
        {
          title: '订单状态',
          dataIndex: 'orderStateVal',
          width: '150px',
          align: 'center',
        },
        {
          title: '提货状态',
          dataIndex: 'pickupStateVal',
          width: '100px',
          align: 'center',
        },
        {
          title: '运输状态',
          dataIndex: 'transportStateVal',
          width: '100px',
          align: 'center',
        },
        {
          title: '派送状态',
          dataIndex: 'dispatchStateVal',
          width: '100px',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: { customRender: 'action' },
        },
      ],

      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
         if(typeof(this.queryParam.startCreateDateVal) == "object"){
          this.queryParam.startCreateDate = moment(this.queryParam.startCreateDateVal).format('YYYY-MM-DD HH:mm:ss')
        }
        if(typeof(this.queryParam.endCreateDateVal) == "object"){
            this.queryParam.endCreateDate = moment(this.queryParam.endCreateDateVal).format('YYYY-MM-DD HH:mm:ss')
        }

        return getOrderManagerList(Object.assign(parameter, this.queryParam)).then((res) => {
          this.loadEnumerationData()
          if(res.code == 0){
             return res.result
          }else{
             this.$notification.error({
              message: '加载失败',
              description: res.message,
            })
          }

        })
      },

    }
  },
  filters: {},
  created() {

  },
  methods: {

    loadEnumerationData() {
      var enumerationParam = { tableName: 'sysCOrder' }
      enumerationData(enumerationParam).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.transportStateData = res.result.transportState
          this.pickupStateData = res.result.pickupState
          this.dispatchStateData = res.result.dispatchState
          this.orderStateData = res.result.orderState
        }
      })

      //企业识别码
      var parameter = { "type": 1}
      findListCustomer(parameter)
      .then(res => {
        if(res.code == 0){
          this.customerCodeList = res.result;            
        }else{
          this.$notification.error({
            message: '服务器繁忙',
            description: res.message
          })
        }
      })  

    },
    handleDetail(record) {
      this.$router.push({ name: 'orderDetail', query: { orderId: record.id } })
    },

  },
}
</script>
